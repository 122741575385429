/* Main Body */

body {
    /* visibility: hidden; */
    position: relative;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    transition: 0.5s;
}

.setup{
    display: none;
}

*{
    text-decoration: none;
    /* font-family: Arial !important; */
}

#main{
    color: black;
}

*,
::before,
::after {
    box-sizing: border-box;
}

li{
    text-decoration: none;
    font-family: inherit;
    color: inherit;
}
a{
    text-decoration: none;
    font-family: inherit;
    color: inherit;
    cursor: pointer !important;
}

body, body > .container-fluid{
    font-size: var(--body-font-size) !important;
}

.hidden{
    display: none !important;
}

.users-hide{
    display: none !important;
}

.hideMe{
    visibility: hidden;
}


/* Toasts */

.toast-sheetloom{
    color: var(--white-color) !important;
    background-color: var(--main-color) !important;
}

.toast{
    --bs-toast-spacing: 0.5rem !important;
    width: auto !important;
}




/* DatePicker */
.datepicker{
    color: black !important;
}






/* Header & Sidebar */

.active {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    color: var(--white-color)
}

.header-box {
    font-style: normal;
    font-weight: 500;
    font-size: var(--header-font-size);
    line-height: 43px;
    padding: 10px 10px 0px 10px;
}

.subtitle-box {
    font-style: normal;
    font-weight: 400;
    font-size: var(--subtitle-font-size);
    line-height: 26px;
    /* letter-spacing: -0.014em; */
    color: var(--subtitle-font-color);
    /* margin-left: 10px; */
}

#logo{
    width: 100%;
    padding: 4px;
    max-width: 170px;
    padding-top: 10px;
    max-width: 170px; 
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: 0.5s;
}

.header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 9999;
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}

.header_img img {
    width: 40px;
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--first-color);
    padding: 0.5rem 1rem 0 0;
    transition: 0.5s;
    z-index: var(--z-fixed);
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
    margin-bottom: 2rem;
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color);
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700;
}

.nav_link {
    position: relative;
    color: var(--first-color-light);
    margin-bottom: 1.5rem;
    transition: 0.3s;
}

.nav_link:hover {
    color: var(--white-color);
}

.nav_icon {
    font-size: 1.25rem;
}

.show {
    left: 0;
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem);
}

.active {
    color: var(--white-color);
}

.active::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
}

.nav i,
.nav span{
    color: white;
}

.crumb-box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 10vh;
}

/* Default styles for the logo container and logo images */
#nav_logo {
    text-align: center; /* Center the logo horizontally */
}

.logo-small {
    max-width: 50px; /* Make sure the logo scales with the screen size */
    position: absolute; /* Use 'fixed' for a fixed position relative to the viewport */
    top: 10; /* Adjust the top position as needed */
    left: 10; /* Adjust the left position as needed */
    z-index: 9999; /* Use a high z-index value to ensure it's above other elements */
}

.logo-large {
    max-width: 175px; /* Make sure the logo scales with the screen size */
    position: absolute; /* Use 'fixed' for a fixed position relative to the viewport */
    top: 10; /* Adjust the top position as needed */
    left: 10; /* Adjust the left position as needed */
    z-index: 9999; /* Use a high z-index value to ensure it's above other elements */
}

#sqsMessageArea{
    position: fixed;
    z-index:1080;
    height:fit-content;
    bottom:2%;
    right:2%;
    font-size:15px;
    margin-left: 2%;
}
  

#logodisplay{
    background-color: var(--main-color);
    min-height: 35px;
    max-height: 35px;
    -webkit-transition: 1s;
    transition: all 1s ease-out;
    opacity: 1;
}

.header_toggle > .bx-x:before {
    content: "\e9b7";
}

#sub-box{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

#btn-sub-box{
    display: flex;
    justify-content: right;
    align-content: center;
    flex-direction: row;
}


[id$="AddSubmitButton"]{

}



.btn.btn-primary.primary-btn, .primary-btn, .btn-danger, .btn-secondary{
    margin: 5px;
    /* width: 81px !important; */
    text-anchor: middle;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 34px;
}

#userEditButton, #userDeleteButton, #userEditCancelButton{
    background-color: white !important;
    color: var(--main-color) !important;
}

#usersTitleBar button{
    z-index: 100;
    position: relative;
}

.pattern-table-stitch-btn{
    background-color: var(--pattern-stitch-btn) !important;
    height: fit-content;
}

.pattern-table-stitch-btn:hover{
    background-color: var(--pattern-stitch-btn-hover) !important;
    height: fit-content;
}

.link-primary{
    color: var(--main-color) !important;
    cursor: pointer;
}

.pat-toggle-btn{
    width: -webkit-fill-available;
    -webkit-transition: .8s;
    transition: .8s;
    border-color: var(--main-color);
    margin-left: 0 !important;
    margin-right: 0 !important;
    border-radius: 0%;
}

a.btn.btn-default._jsonform-array-addmore{
    background: url(../assets/plus-square.svg) no-repeat;
    height: 30px;
    padding: auto;
    background-position-y: center;
    background-position-x: center;
}

a.btn.btn-default._jsonform-array-deletelast{
    background: url(../assets/dash-square.svg) no-repeat;
    height: 30px;
    padding: auto;
    background-position-y: center;
    background-position-x: center;    
}

.link-primary:hover{
    color: var(--main-hover-color) !important;
}

span._jsonform-array-buttons{
    height: 43px;
    width: 57px;
    position: relative;
    border-radius: 5px;
}

.submit-btn{
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}

#stitchesEditPreviewButton{
    color: var(--main-color);
    background-color: white !important;
    border-color: var(--main-color) !important;
}

.btn-primary {
    background-color: var(--main-color) !important;
    border-color: var(--main-color) !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: var(--main-hover-color) !important;
}

.btn-outline-primary {
    border-color: var(--main-color);
    color: var(--main-color);
}

.btn-outline-primary:hover {
    background-color: var(--main-color);
    color: var(--hvr-text-color);
}

.form-botton-btns{
    margin-bottom: 25px !important;
    bottom: 0px;
    position: absolute;
    margin-top: 25px !important;
    padding-top: 15px !important;
    padding-left: 3% !important;
    padding-right: 3% !important;
    height: 6%;
    background-color: white;
}

.test-btn {
    background: aliceblue;
    width: fit-content !important;
    padding: 6px 12px !important;
    margin: 5px !important;
    border: 1px solid lightsteelblue;
}

#stitchFormCopyBtn{
    width: fit-content !important;
    max-width: 90% !important;
    padding-left: 10px !important;
    padding-right: 14px !important;
    color: var(--main-color);
    background-color: white !important;
    border-color: var(--main-color) !important;
    margin-bottom: 20px !important;
    min-width: fit-content;
    min-width: -moz-fit-content;
}

#csvFormStitchRefCopyBtn{
    max-width: 40% !important;
    width: fit-content !important;
    color: var(--main-color);
    background-color: white !important;
    border-color: var(--main-color) !important;
}

#stitchFormCopyBtn i, #csvFormStitchRefCopyBtn i{
    color: var(--main-color);
}

#viewToggle button, #viewToggle button:hover, #viewToggle button:focus, #viewToggle button:active{
    background-color: white !important;
    color: #626979 !important;
    border-color: #626979 !important;
}

#stitchesRefreshButton, #parametersRefreshButton, #connectionsRefreshButton, #groupsRefreshButton, #usersRefreshButton{
    padding-left: 14px !important;
    padding-right: 14px !important;
    color: var(--main-color);
    background-color: white !important;
    border-color: var(--main-color) !important;
}

.explorer-weave-column > .btn-primary:active{
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

#resetPasswordBtn{
    border: none !important;
    background: none !important;
}
#resetPasswordBtn:hover:not([disabled]){
    color: var(--main-color) !important;
}
#resetPasswordBtn:disabled{
    color: #a5a9b5 !important;
}

[class$="btn"] {
    cursor: pointer;
}
.weave-btn img{
    height: 30px !important;
}
.download-btn img, .inner-download-btn img, .edit-tags-btn img{
    height: 20px !important;
}
.weave-btn:hover, .edit-tags-btn:hover, .download-btn:hover, .inner-download-btn:hover{
    background-color: aliceblue;
    border-radius: 12px;
}

.btn-hover:hover {
    background-color: var(--main-color) !important;
    color: white !important;
}

.dataChecklistFormat {
    margin-top: 1px;    
}








/* OffCanvas */

.offcanvas{
    left:auto !important
}

.offcanvas-backdrop.show{
    opacity: 0.75 !important;
    width: 100% !important;
}

.dropdown > button{
    width: auto;
}

.offcanvas-footer .d-grid{
    text-align: center;
}

.offcanvas-footer{
    border-top-style: solid !important;
    border-top: thin #E1E7FE;
    padding: 0.5vw;
}

.canvasImage{
    width: 100%;
    text-align: center;
}

.canvasImage > img{
    height: 2.5vw;
}

.offcanvas-header{
    border-bottom-style: solid !important;
    border-bottom: thin #E1E7FE;
    padding: 0.5vw;
}

.stitch-query-field{
    padding: 10px !important;
    border-radius: .375rem !important;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.form-group {
    padding-bottom: 8px !important;
}

.form-group > label {
    font-size: var(--form-font-size) !important;
}

.form-group[class^='jsonform-error-usersGroups['], .form-group[class*='jsonform-error-usersGroups['], .form-group[class^='jsonform-error-patternGroups['], .form-group[class*='jsonform-error-patternGroups['] , .form-group[class^='jsonform-error-parameters['], .form-group[class*='jsonform-error-parameters[']  {
    display: inline-flex!important;
}

.form-group[class^='jsonform-error-usersGroups['] label, .form-group[class*='jsonform-error-usersGroups['] label, .form-group[class^='jsonform-error-patternGroups['] label, .form-group[class*='jsonform-error-patternGroups['] label , .form-group[class^='jsonform-error-parameters['] label, .form-group[class*='jsonform-error-parameters['] label  {
    align-self: center;
}

.controls ul {
    padding-left: 2rem;
    padding-left: 8%;
}


form > div > .form-group > label {
    padding-bottom: 10px;
}

._jsonform-array-buttons a.btn.btn-default._jsonform-array-deletelast {
    display: none !important;
}

form span, small {
    color: #858994;
}

.glyphicon-list::before {
    content: "\f3fd";
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 5px;
    padding: 4px;
    box-shadow: 1;
    margin: 4px;
    cursor: grab;
}
.glyphicon-list:active:before{
    cursor: grabbing !important;
}

span.draggable.line {
    position: relative;
    top: 18px;
    height: 70px;
}

._jsonform-array-ul.ui-sortable{
    list-style-type: none;
    padding-left: inherit;
    display: grid;
}
._jsonform-array-ul.ui-sortable li{
    display: inline-flex;
    height: 70px;
}
._jsonform-array-ul.ui-sortable fieldset{
    height: 70px;
    width: -webkit-fill-available;
}

/* Add some spacing to the div */
.icon-div {
    position: relative; /* Set to relative to enable absolute positioning of the icon */
}

/* Position the icon absolutely on the right side */
.icon {
    position: absolute;
    display: inline-block;
    top: 50%; /* Vertically center the icon */
    right: 10px; /* Adjust this value as needed */
    transform: translateY(-50%); /* Correct vertical alignment */
}

.form-control{
    padding-right: 40px;
}

.form-control:focus{
    border-color: var(--main-color) !important;
    box-shadow: 0 0 0 0.25rem #0067ff40 !important;
}

.disable-click {
    background-color: #ccc; /* Set the background color to grey */
    color: #888; /* Set the text color to a lighter grey */
    border: none; /* Remove the border */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}

div .icon {
    pointer-events: none;
}









/* Modal CSS */

.modal{
    width: 100% !important;
}

#index .modal-backdrop {
    background-color: transparent !important;
}

.modal-backdrop.show {
    opacity: 0.75 !important;
    width: 100% !important;
}

.modal-dialog{
    min-width: 250px;
}

.modal-backdrop.fade.show {
    z-index: 1020;
}

#cookiesModal .modal-content{
    top: 20%;
    border-radius: 3px;
    border-color: black;
}

.text-muted {
    font-size:var(--param-text-muted);
    margin: 10px;
}






/* Cards */

.card-header .fa {
    transition: .3s transform ease-in-out;
}
.card-header .collapsed .fa {
    transform: rotate(90deg);
}

#patternCards > .card{
    max-width: 100%;
    width: fit-content;
    margin-top: 10px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.folderName {
    margin-top:1px;
    overflow: hidden;
}

.folderImgDiv {
    margin-right: 5px;
}

.patternCardTitle span{
    padding-right: .5rem!important;
}

.hiddenPatternCard{
    height: 0% !important;
    
}

.patternCardTitle {
    cursor: pointer;
}

.foldercard .card, #addfolder .card{
    min-width: 150px;
    /* height: 81px; */
    padding: 1px;
    margin-left: 4px;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow-wrap: normal;
    overflow: hidden;
    max-width: 100%;
    cursor:pointer;
    /* width: 32%; */
}

#explorerFolders .card-body{
    overflow: hidden;
}

#explorerFolders > div {
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 6px;
    display: contents;
}

#explorerFolders{
    height: fit-content;
    padding-bottom: 10px;
    max-width: 100%;
    margin-left: 1px;
    /* display: inline-flex; */
}

#smallLogo img{
    width: 2.2rem;
    height: 2.2rem;
    margin-right: 0px;
}

.card:hover{
    background-color: #ececec;
}

.curFolder {
    opacity: 0.5; /* Set the opacity to make it appear grayed out */
    pointer-events: none; /* Disable pointer events to make it unclickable */
  }








/* Misc */

.selector-for-some-widget {
    box-sizing: content-box;
}

#supportForm{
    padding-left: 20px;
    width: 50%;
    margin: auto;
    min-width: 245px;
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
    color: red;
}

.fa-check-circle:before {
    color: limegreen;
}

em{
    color: red !important;
}

.email{
    color: blue !important;
}

.text-align-center{
    text-align: center !important;
}

.spinner-border.spinner-border-sm {
    float: left;
    color: white;
    height: 20px !important;
    width: 20px !important;
    margin: 2px !important;
    margin-left: 5px !important;
}

#pattBrowserSpinner{
    position: fixed !important;
    top: 50% !important;
    right: 50% !important;
    bottom: 50% !important;
    left: 50% !important;
}

.bi.bi-x-circle{
    color: red;
}

.bi.bi-check-circle {
    color: limegreen;
}

.bi.bi-arrow-clockwise {
    color: var(--main-color);
}









/* pagination */

.pagination{
    justify-content: end !important;
    margin-top: 8px !important;
    margin-right: 8px !important;
}
.pagination a{
    font-size: inherit;
    padding: 4px 6px !important;
    border: 1px solid #ddd !important;
    margin: -1px !important;
    margin-left: 0px !important;
    color: var(--main-color);
}

.pagination a:hover{
    color: var(--hvr-text-color);
    background-color: var(--main-color);
}

.paginate_button.previous.disabled, .paginate_button.previous.disabled:hover, .paginate_button.next.disabled, .paginate_button.next.disabled:hover {
    color: darkgrey !important;
}
.paginate_button {
    color: var(--main-color) !important;
    border-radius: 0px !important;
}
.paginate_button.active a{
    background-color: var(--main-hover-color) !important;
    border-color: var(--main-hover-color) !important;
    color: white !important;
}
.paginate_button.previous a{
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}
.paginate_button.next a{
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.custom-file-button input[type=file] {
    margin-left: -2px !important;
  }
  
  .custom-file-button input[type=file]::-webkit-file-upload-button {
    display: none;
  }
  
  .custom-file-button input[type=file]::file-selector-button {
    display: none;
  }
  
  .custom-file-button:hover label {
    background-color: #dde0e3;
    cursor: pointer;
  }






/* Media Queries */

@media (width >= 1200px){
    .col-xl-2 {
        width: 15% !important;
    }
}

@media screen and (min-width: 768px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem);
    }

    .header {
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    }

    .header_img {
        width: 40px;
        height: 40px;
    }

    .header_img img {
        width: 45px;
    }

    .l-navbar {
        left: 0;
        padding: 1rem 1rem 0 0;
    }

    .show {
        width: calc(var(--nav-width) + 156px);
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px);
    }
}

@media (max-width: 350px) {
    #sqsMessageArea{font-size: 0.7rem;}

    #folderCard{font-size: 0.7rem;}

    .header-box h2{font-size: 1.4rem;}
    .subtitle-box{font-size: 1rem;}

    .explorer-name-column{
        font-size: 0.8rem !important;
    }
}

@media (max-width: 275) {
    /* #sqsMessageArea{font-size: 0.5rem;} */

    #folderCard{font-size: 0.5rem;}

    .header-box h2{font-size: 1rem;}
    .subtitle-box{font-size: 0.8rem;}

    .explorer-name-column{
        font-size: 0.6rem !important;
    }
}

@media (min-width: 768px) {
    .text-md-right {
        text-align: right;
    }
}

@media (max-height: 800px) {
    .nav_link{
        margin-bottom: 0.5rem;
    }
    .nav{
        overflow-y: auto;
        flex-direction: inherit;
    }
}

@media (max-height: 600px) {
    .nav_link{
        margin-bottom: 0.2rem;
    }
}

#csvOptionsModalForm{
    appearance: auto !important;
}

.query-builder-btn {
    visibility: hidden;
}