:root {
    --body-font-size: 1rem;
    --table-font-size: 0.9rem;
    --form-font-size: 1.1rem;
    --main-color:#264EAE;
    --main-hover-color: #1D3E89;
    --hvr-text-color: white;
    --header-font-size: 2rem;
    --subtitle-font-size: 1.2rem;
    --subtitle-font-color: #626979;
    --datatable-searchbox: #f4f4f4;
    --datatable-selected-row: rgb(229 240 255 / 90%);
    --datatable-selected-font-colour: black;
    --navbar-link-active: rgba(0, 0, 0, 0.3);
    --navbar-link-text: white;
    --navbar-link-text-active: white;
    --pattern-stitch-btn: aliceblue;
    --pattern-stitch-btn-hover: rgb(208, 233, 255);
    --param-text-muted: 0.75rem;
    --header-height: 3rem;
    --nav-width: 68px;
    --first-color: #264EAE;
    --first-color-light: white;
    --white-color: white;
    --body-font: "Nunito", sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100;
}