/* Datatables */

#tableRow{
  padding-bottom: 3vw;
}

table.dataTable td {
  word-break: break-word;
}

.dropdown-menu {
  padding: 15px;
}
.dropdown-menu hr {
  padding: 0px !important;
  margin: 5px !important;
}

div.dataTables_wrapper div.dataTables_filter input, #patternsSearch input {
  background: url("../assets/search.svg") no-repeat !important;
  height:30px !important;
  background-position-x: 8px !important;
  background-position-y: center !important;
  padding-left: 2.1rem !important;
  display: inline-block !important;
  width: 75% !important;    
  background-color: var(--datatable-searchbox) !important;
  border: none;
  margin-bottom: 6px !important;
  appearance: auto;
}

.dataTables_length select{
  margin-bottom: 6px !important;
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

table.dataTable>tbody>tr.selected>*{
  color: var(--datatable-selected-font-colour) !important;
  box-shadow: inset 0 0 0 9999px var(--datatable-selected-row) !important;
}

.dropdown-menu a{
  padding: 5px;
  width: 100%;
  white-space: nowrap;
}

.dropdown-menu a:hover{
  background-color: #ffffff !important; 
  color: var(--bs-link-hover-color) !important;
}
.dropdown-menu a.active, .dropdown-menu a.active:hover{
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

td, th{
  font-size: var(--table-font-size) !important;
  vertical-align: middle !important;
}

table.dataTable.no-footer{
  border-bottom: 1px solid #ddd !important;
  
}

table.dataTable thead th, table.dataTable thead td{
  border-bottom: 1px solid #ddd !important;
}

.slider .w-50{
  max-width: 50% !important;
}

.sorting_disabled:before{
  content: "" !important;
}
.sorting_disabled:after{
  content: "" !important;
}

[title~="Download"],
[title~="csvExpand"],
table.dataTable>tbody>tr>td.select-checkbox:before,
table.dataTable>tbody>tr>td.select-checkbox:after
{
  cursor:pointer;
}

div.slider {
  display: none;
}

table.dataTable tbody tr.odd:hover td,
table.dataTable tbody tr.even:hover td
{
  background-color: #ececec;
}
  
  
table.dataTable thead th.select-checkbox {
  position: relative;
}

table.dataTable thead th.select-checkbox:before,
table.dataTable thead th.select-checkbox:after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}
table.dataTable thead tr th.select-checkbox:before {
  content: ' ';
  margin-top: -6px;
  margin-left: -6px;
  border: 1px solid black;
  border-radius: 3px;
}

table.dataTable > thead > tr.selected > td.select-checkbox:before,
table.dataTable > thead > tr.selected > th.select-checkbox:before {
  border: 1px solid rgb(26, 8, 8) !important;
}

table.dataTable thead tr.selected th.select-checkbox:after {
  content: "✓" !important;
  margin-top: -12px;
  margin-left: -8px;
  font-size: 20px;
  font-weight: 500;
}
